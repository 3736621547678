@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  height: 32px;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-secondary);
}

*::-webkit-scrollbar-thumb:active {
  background-color: var(--color-secondary);
}

.required-field {
  @apply after:ml-1 after:content-['*'] after:text-red-500;
}

.range-thumb::-webkit-slider-thumb {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-primary);
}

/* Hide scrollbar by default */
.sidebar-nav {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.sidebar-nav::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

/* Show scrollbar on hover */
.sidebar-nav:hover {
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: auto; /* IE and Edge */
}

.sidebar-nav:hover::-webkit-scrollbar {
  display: block;
  width: 6px;
}

.sidebar-nav:hover::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-nav:hover::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}

.sidebar-nav:hover::-webkit-scrollbar-thumb:hover {
  background-color: rgba(156, 163, 175, 0.7);
}
